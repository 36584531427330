import styled from 'styled-components'
import ListLayout from '../../components/layout/main/ListLayout'
import {
	useGetAllFeeAmount,
	useGetAllStakingAmount,
	useGetInAndOutExchange,
} from '../../core/services/react-query/panel'
import CoinRow from '../../components/common/common/CoinRow'
import { formatNumber } from '../../core/utils/common'
import Text from '../../core/utils/Text'
import { ClipLoader } from 'react-spinners'
import { getMainTheme } from '../../core/utils/theme'

const Dashboard = () => {
	const { data: inAndOut, isLoading: inAndOutLoading } = useGetInAndOutExchange()
	const { data: allFees, isLoading: allFeesLoading } = useGetAllFeeAmount()
	const { data: allStakings, isLoading: allStakingLoading } = useGetAllStakingAmount()

	return (
		<ListLayout>
			<Grid>
				<div className={'relative'} style={{ gridArea: 'a' }}>
					<div
						className={`
                    rounded border-[1px] border-slate-200 border-opacity-20
                    p-5 dark:bg-bgDarkPrimary h-[250px] overflow-y-auto
                    text-sm dark:text-gray-100
                `}
					>
						{allStakingLoading ? <Loading /> : null}
						<Text tid={'all-staking-amounts'} />
						{Object.keys(allStakings || {}).map((k, idx) => {
							const value = allStakings[k]
							const isLast = Object.keys(allStakings).length === idx + 1
							return (
								<div
									className={`grid grid-cols-2 py-3 ${
										isLast ? '' : 'border-b-[1px]'
									} px-5 border-slate-500 border-opacity-10`}
									key={k}
								>
									<CoinRow coin={{ abbr: k }} />
									<span>{formatNumber(value, { type: k })}</span>
								</div>
							)
						})}
					</div>
				</div>

				<div className={'relative'} style={{ gridArea: 'b' }}>
					<div
						className={`
                    rounded border-[1px] border-slate-200 border-opacity-20
                    p-5 dark:bg-bgDarkPrimary h-[120px] text-sm dark:text-gray-100
                `}
					>
						{inAndOutLoading ? <Loading /> : null}
						<div
							className={
								'flex items-center justify-center py-2 border-b-[1px] border-slate-500 border-opacity-20'
							}
						>
							<Text tid={'in-all-exchange'} />
						</div>
						<div className={'flex mt-5 items-center justify-center'}>
							<span dir={'ltr'}>{formatNumber(inAndOut?.in, { type: 'usdt' })} USDT</span>
						</div>
					</div>
				</div>

				<div className={'relative'} style={{ gridArea: 'c' }}>
					<div
						className={`
                    rounded border-[1px] border-slate-200 border-opacity-20
                    p-5 dark:bg-bgDarkPrimary h-[120px] text-sm dark:text-gray-100
                `}
					>
						{inAndOutLoading ? <Loading /> : null}
						<div
							className={
								'flex items-center justify-center py-2 border-b-[1px] border-slate-500 border-opacity-20'
							}
						>
							<Text tid={'out-all-exchange'} />
						</div>
						<div className={'flex mt-5 items-center justify-center'}>
							<span dir={'ltr'}>{formatNumber(inAndOut?.out, { type: 'usdt' })} USDT</span>
						</div>
					</div>
				</div>

				<div className={'relative'} style={{ gridArea: 'e' }}>
					<div
						style={{ gridArea: 'd' }}
						className={`
                    rounded border-[1px] border-slate-200 border-opacity-20
                    p-5 dark:bg-bgDarkPrimary h-[120px] text-sm dark:text-gray-100
                `}
					>
						{allFeesLoading ? <Loading /> : null}
						<div
							className={
								'flex items-center justify-center py-2 border-b-[1px] border-slate-500 border-opacity-20'
							}
						>
							<Text tid={'all-fees-amount'} />
						</div>
						<div className={'flex mt-5 items-center justify-center'}>
							<span dir={'ltr'}>{formatNumber(allFees, { type: 'usdt' })} USDT</span>
						</div>
					</div>
				</div>
			</Grid>
		</ListLayout>
	)
}

const Grid = styled.div`
	display: grid;
	width: 100%;
	gap: 12px;
	grid-template-areas:
		'a a b c d d'
		'a a e e f f';

	grid-template-columns: 1fr 1fr 1.5fr 1.5fr 0.75fr 0.75fr;
`

const Loading = () => {
	return (
		<div
			className={
				'absolute left-0 top-0 w-full h-full backdrop-blur-sm flex items-center justify-center'
			}
		>
			<ClipLoader size={20} color={getMainTheme().active} />
		</div>
	)
}

export default Dashboard
