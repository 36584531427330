import LoginView from '../components/main/login/LoginView'
import { routes } from '../core/constants/routes'
import { useMainContext } from '../core/contexts/main'
import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

const Login = () => {
	const navigate = useNavigate()
	const { token } = useMainContext()
	useEffect(() => {
		if (token) navigate(routes.main)
	}, [token])

	return <LoginView />
}

export default Login
