import { FaPlus } from "react-icons/fa"
import ListLayout from "../../../components/layout/main/ListLayout"
import RSpecializationList from "../../../components/main/medical/specializations/RSpecializationList"
import SpecializationList from "../../../components/main/medical/specializations/SpecializationList"
import { TABLET_SIZE } from "../../../core/constants/common"
import { useWindowSize } from "../../../core/hooks/common/useWindowSize"
import { useGetDoctorCategories } from "../../../core/services/react-query/user"
import { PageCard } from "../../../styles/CommonStyles"
import Text from "../../../core/utils/Text"
import { useState } from "react"
import AddSpecializationModal from "../../../components/main/medical/specializations/AddSpecializationModal"


const Specializations = () => {

    const { width } = useWindowSize()
    const { data: categories, isLoading } = useGetDoctorCategories()
    const Component = width > TABLET_SIZE ? SpecializationList : RSpecializationList

    const [addModal, setAddModal] = useState(false)

    return (
        <ListLayout>
            <PageCard>
                <div className={'flex justify-end'}>
                    <div
                        className={'rounded-md bg-gray-500 bg-opacity-20 py-2 px-5 flex items-center gap-2 text-sm dark:text-gray-300 cursor-pointer'}
                        onClick={() => setAddModal(true)}
                    >
                        <FaPlus />
                        <Text tid={'add-specialization'} />
                    </div>
                </div>
                <Component
                    data={{ data: { list: categories }, loading: isLoading }}
                />

                <AddSpecializationModal
                    open={addModal}
                    onClose={() => setAddModal(false)}
                />
            </PageCard>
        </ListLayout>
    )
}

export default Specializations