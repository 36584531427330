import { doctorAttributes } from "../../../../core/constants/headers"
import useGetTableIndex from "../../../../core/hooks/layout/useGetTableIndex"
import { Column, Relative, Row } from "../../../../styles/CommonStyles"
import TableLayout from "../../../layout/main/TableLayout"
import Operation from "../../../common/utils/Operation"
import { useNavigate } from "react-router-dom"


const DoctorsList = ({ data }) => {

    const navigate = useNavigate()
    const { getTableIndex } = useGetTableIndex()
    const { headers, cs } = doctorAttributes

    const onDetailsClicked = (doctor) => {
        navigate(
            `/medical/doctors/${doctor.user.doctor_id}`
        )
    }

    return (
        <TableLayout
            data={data}
            headers={headers}
            cs={cs}
        >
            {data?.data?.list?.map((item, idx) => {

                return (
                    <Relative key={item.id}>
                        <Row index={idx} cs={cs}>
                            <Column>
                                {getTableIndex(idx)}
                            </Column>
                            <Column>
                                {item.user.doctor_id}
                            </Column>
                            <Column>
                                {item.user.full_name}
                            </Column>
                            <Column>
                                {item.user.email}
                            </Column>
                            <Column>
                                <div className={'flex items-center gap-2'}>
                                    <img
                                        src={item.specialization.images[0].url}
                                        width={52}
                                        height={52}
                                        alt={item.specialization.name}
                                    />
                                    <span>{item.specialization.name}</span>
                                </div>
                            </Column>
                            <Column>
                                {item.user.mobile_number}
                            </Column>
                        </Row>
                        <Column operation>
                            <Operation
                                onDetailsClicked={() => onDetailsClicked(item)}
                                hasDetails
                            />
                        </Column>
                    </Relative>
                )
            })}
        </TableLayout>
    )
}

export default DoctorsList