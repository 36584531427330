import { Link, useParams } from "react-router-dom"
import { useGetADoctor, useGetContactTypes, useGetDoctorTimeSlots, useUpdateTypePrice } from "../../../core/services/react-query/medical"
import ListLayout from "../../../components/layout/main/ListLayout"
import { Background, DText } from "../../../styles/CommonStyles"
import Text from "../../../core/utils/Text"
import Input from "../../../components/common/input/Input"
import { useEffect, useState } from "react"
import { deepCopy } from "../../../core/utils/common"
import { ClipLoader } from "react-spinners"
import { TABLET_SIZE } from "../../../core/constants/common"
import { useWindowSize } from "../../../core/hooks/common/useWindowSize"
import TimeSlotsList from "../../../components/main/medical/time_slots/TimeSlotsList"
import RTimeSlotsList from "../../../components/main/medical/time_slots/RTimeSlotsList"

let loadingTypePrice = { idx: -1, type: null }

const DoctorDetails = ({ }) => {

    const params = useParams()
    const { data: doctor, refetch } = useGetADoctor(params.id)
    const { data: time_slots, isLoading: loadingTimeSlots } = useGetDoctorTimeSlots(params.id)
    const { data: contactTypes } = useGetContactTypes()
    const { mutateAsync: updateTypePrice } = useUpdateTypePrice(refetch)

    const [typePrices, setTypePrices] = useState([])

    useEffect(() => {
        if (!!doctor) {
            setTypePrices(doctor.types_prices)
        }
    }, [doctor])

    const onChangeTypePrice = (v, i) => {
        const newTypePrices = deepCopy(typePrices)
        newTypePrices[i].price = v
        setTypePrices(newTypePrices)
    }

    const onUpdateTypePrice = async (t, idx) => {
        loadingTypePrice = { type: t.type, idx }
        const contact_type = contactTypes.find(x => x.type === t.type)

        try {
            await updateTypePrice({
                payload: {
                    contact_type_id: contact_type.id,
                    price: t.price
                },
                doctor_id: doctor?.user?.doctor_id
            })
        } catch (_) { }

        loadingTypePrice = { type: null, idx: -1 }
    }

    const { width } = useWindowSize()
    const TimeSlotComp = width > TABLET_SIZE ? TimeSlotsList : RTimeSlotsList


    return (
        <ListLayout>
            <div className={'grid grid-cols-1 md:grid-cols-2 w-full gap-12 text-sm textPrimary'}>
                <div className={'rounded p-4 shadow-md cardBg borderMain'}>

                    <div className={'flex items-center gap-2'}>
                        <Text tid={'user-details'} />
                        <Link to={`/users/${doctor?.user?.id}`}>
                            <span className={'underline'}>{`( ${doctor?.user?.id} )`}</span>
                        </Link>
                    </div>

                    <div className={'grid grid-cols-2 w-full gap-3 mt-5'}>
                        <Text tid={'full_name'} />
                        <span className={'textMain'}>{doctor?.user?.full_name}</span>
                        <div className={'col-span-2 mx-auto w-full bg-opacity-20 h-[1px] bg-gray-500'} />

                        <Text tid={'email'} />
                        <span className={'textMain'}>{doctor?.user?.email}</span>
                        <div className={'col-span-2 mx-auto w-full bg-opacity-20 h-[1px] bg-gray-500'} />

                        <Text tid={'mobile_number'} />
                        <span className={'textMain'}>{doctor?.user?.mobile_number}</span>
                        <div className={'col-span-2 mx-auto w-full bg-opacity-20 h-[1px] bg-gray-500'} />

                        <Text tid={'is_kyc_passed'} />
                        <span className={'textMain'}>{doctor?.user?.is_kyc_passed}</span>
                    </div>
                </div>

                <div className={'rounded p-4 shadow-md cardBg borderMain'}>
                    <div className={'flex items-center gap-2'}>
                        <Text tid={'doctor-details'} />
                        <span>{`( ${doctor?.user?.doctor_id} )`}</span>
                    </div>

                    <div className={'grid grid-cols-2 w-full gap-3 mt-5'}>
                        <div className={'flex items-center gap-2'}>
                            <img
                                src={doctor?.specialization?.images[0]?.url}
                                width={52}
                                height={52}
                                alt={doctor?.specialization?.name}
                            />
                            <span>{doctor?.specialization?.name}</span>
                        </div>
                        <div className={'grid grid-cols-2 gap-3'}>
                            <Text tid={'rating'} />
                            <span className={'textMain'}>{doctor?.rating}</span>
                            <Text tid={'number_of_reviews'} />
                            <span className={'textMain'}>{doctor?.number_of_reviews}</span>
                        </div>
                        <div className={'col-span-2 mx-auto w-full bg-opacity-20 h-[1px] bg-gray-500'} />
                    </div>

                    <div className={'mt-4'}>
                        <Text tid={'types_prices_note'} />
                    </div>
                    <div className={'grid grid-cols-1 md:grid-cols-2 gap-5 mt-4'}>
                        {typePrices.map((item, idx) => {

                            const isLoading = loadingTypePrice.type === item.type &&
                                idx === loadingTypePrice.idx

                            return (
                                <div key={item.type} className={'flex items-center gap-2'}>
                                    <Background bg={'mainBg'} className={'w-[70%]'}>
                                        <Input
                                            height={'48px'}
                                            label={item.type}
                                            value={item.price}
                                            onInputChange={(v) => onChangeTypePrice(v, idx)}
                                        />
                                    </Background>

                                    <div className={`
                                        h-[42px] flex-center px-3 cursor-pointer rounded borderMain mt-[-16px]
                                         bg-blue-400 bg-opacity-30 text-xs textMain min-w-[100px]`}

                                        onClick={() => onUpdateTypePrice(item, idx)}
                                    >
                                        {isLoading ?
                                            <ClipLoader size={14} />
                                            :
                                            <Text tid={'update-price'} />
                                        }
                                    </div>
                                </div>
                            )
                        })}
                    </div>

                </div>
                <div className={'rounded p-4 shadow-md cardBg borderMain md:col-span-2'}>
                    <TimeSlotComp
                        data={{ loading: loadingTimeSlots, data: { list: time_slots } }}
                        hasPagination={false}
                    />
                </div>
            </div>

        </ListLayout>
    )
}

export default DoctorDetails