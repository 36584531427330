import { timeSlotAttributes } from "../../../../core/constants/headers"
import useGetTableIndex from "../../../../core/hooks/layout/useGetTableIndex"
import { Column, Relative, Row } from "../../../../styles/CommonStyles"
import TableLayout from "../../../layout/main/TableLayout"
import Operation from "../../../common/utils/Operation"
import { Link, useNavigate } from "react-router-dom"
import { formatDate } from "../../../../core/utils/common"
import { useMainContext } from "../../../../core/contexts/main"


const TimeSlotsList = ({ data, hasPagination = true }) => {

    const { lang } = useMainContext()
    const navigate = useNavigate()
    const { getTableIndex } = useGetTableIndex()
    const { headers, cs } = timeSlotAttributes

    const typeBgs = {
        phone: 'bg-purple-300',
        visit: 'bg-blue-300',
        chat: 'bg-red-300',
        'online-video-call': 'bg-gray-100'
    }

    return (
        <TableLayout
            data={data}
            headers={headers}
            cs={cs}
            hasPagination={hasPagination}
        >
            {data?.data?.list?.map((item, idx) => {

                return (
                    <Relative key={item.id}>
                        <Row index={idx} cs={cs}>
                            <Column>
                                {getTableIndex(idx)}
                            </Column>
                            <Column>
                                {item.id}
                            </Column>
                            <Column>
                                <Link to={`/users/${item.doctor.user.id}`}>
                                    <span className={'underline textMain'}>{`${item.doctor.user.full_name}`}</span>
                                </Link>
                            </Column>
                            <Column>
                                <div className={'flex items-center gap-2'}>
                                    <img
                                        src={item.doctor.specialization.images[0].url}
                                        width={52}
                                        height={52}
                                        alt={item.doctor.specialization.name}
                                    />
                                    <span>{item.doctor.specialization.name}</span>
                                </div>
                            </Column>
                            <Column>
                                <div className={`
                                rounded border-[1px] dark:border-gray-400 border-gray-600 border-opacity-50 px-10 py-2
                                ${typeBgs[item.contact_type] || 'bg-green-500'} bg-opacity-20
                                `}>
                                    <span>{item.contact_type}</span>
                                </div>
                            </Column>
                            <Column>
                                <span>{formatDate(new Date(item.available_date), 'date', lang)}</span>
                            </Column>

                            <Column>
                                <span>{item.start_time.slice(0, 5)}</span>
                            </Column>
                        </Row>
                    </Relative>
                )
            })}
        </TableLayout>
    )
}

export default TimeSlotsList


