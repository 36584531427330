import { useEffect, useState } from "react";
import { useProfileQuery } from "../../../services/react-query/profile";


const useGetProfileOrUser = (detail, userDetail) => {

    const [user, setUser] = useState(null)
    const { data: profileQuery } = useProfileQuery()

    useEffect(() => {
        if (detail && userDetail) setUser(userDetail)
    }, [detail, userDetail])

    useEffect(() => {
        if (!detail && profileQuery) setUser(profileQuery)
    }, [detail, profileQuery])

    return {
        user
    }
}

export default useGetProfileOrUser