import { useParams } from "react-router-dom";
import PageNotFound from "../../components/layout/main/PageNotFound";


const UserSubDetails = () => {

    const params = useParams()

    const components = {

    }

    const Comp = components[params.tab]

    if (!Comp) {
        return <PageNotFound />
    }

    return (
        <Comp />
    )
}

export default UserSubDetails
