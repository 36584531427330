import { CloseIcon, OpenIcon, SearchInput, SelectWrapper } from "../../../styles/components/common/select-styles";
import { DText } from "../../../styles/CommonStyles";
import Text from "../../../core/utils/Text";
import Dropdown from "./Dropdown";
import { useEffect, useState } from "react";
import CoinRow from "../common/CoinRow";
import { deepCopy } from "../../../core/utils/common";
import { useGetCurrencies } from "../../../core/services/react-query/setting/currencies";


const Select = ({
    value,
    onValueChange,
    placeHolder,
    options,
    width,
    height,
    dropdownWidth,
    direction = 'bottom',
    size = 'big',
    fontSize = 'm',
    isCoin
}) => {

    const [filteredOptions, setFilteredOptions] = useState([])

    useEffect(() => {
        setFilteredOptions(options)
    }, [options])

    const [search, setSearch] = useState('')

    const onSearchChange = (value) => {
        if (value) {
            let _value = value.toLowerCase()
            const temp = options.filter(item =>
                item.abbr.includes(_value)
            )
            setFilteredOptions(temp)
        } else {
            setFilteredOptions(options)
        }
    }

    useEffect(() => {
        onSearchChange(search)
    }, [search])

    const _onValueChange = (idx) => {
        onValueChange(idx)
    }


    const TSelect = (props) => {

        const { open } = props

        useEffect(() => {
            if (!open) setSearch('')
        }, [open])

        return (
            <SelectWrapper
                height={height}
                size={size}
                {...props}
            >
                {(isCoin && open) ?
                    <SearchInput
                        value={search}
                        onChange={(e) => setSearch(e?.target?.value)}
                        autoFocus
                    />
                    :
                    <DText main fontSize={fontSize}>

                        {(isCoin && value) ?
                            <CoinRow
                                coin={value}
                            />
                            :
                            <Text tid={value} />
                        }
                    </DText>
                }


                {open ?
                    <CloseIcon size={20} />
                    :
                    <OpenIcon size={20} />
                }
            </SelectWrapper>
        )
    }

    return (
        <>
            <Dropdown
                width={width}
                options={filteredOptions}
                Controller={TSelect}
                minWidth={dropdownWidth}
                bottom={direction === 'top' && '110%'}
                top={direction === 'top' && 'unset'}
                onOptionClicked={_onValueChange}
                selected={value}
                isCoin={isCoin}
            />
        </>
    )
}


export default Select
