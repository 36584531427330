import { useState } from "react";
import { useCreateDoctor, useGetDoctorCategories } from "../../../../../core/services/react-query/user"
import Input from "../../../../common/input/Input";
import { Background, DText } from "../../../../../styles/CommonStyles";
import Text from "../../../../../core/utils/Text";
import Select from "../../../../common/dropdown/Select";
import ActionButton from "../../../../common/buttons/ActionButton";
import { useQueryContext } from "../../../../../core/contexts/query";
import { ReactComponent as UserAccepted } from "../../../../../assets/illustrations/setting/user-accepted.svg";



const CreateDoctor = ({ user, refetch }) => {

    const { setToast } = useQueryContext()
    const { data: categories } = useGetDoctorCategories()
    const { mutate: createDoctor, isLoading } = useCreateDoctor(refetch)

    const [mobile, setMobile] = useState('')
    const [category, setCategory] = useState(null)

    const onSubmitClicked = () => {
        if (!mobile || !category) {
            setToast({ isError: true, show: true, message: 'fill-inputs-error' })
            return
        }
        createDoctor({
            specialization_id: category.id,
            user_id: user.id,
            mobile_number: mobile
        })
    }

    return (
        <div className={`mx-auto w-[320px] flex flex-col gap-4 py-5 ${user?.doctor_id ? 'min-h-[200px]' : 'min-h-[600px]'}`}>
            {!!user?.doctor_id ?
                <>
                    <UserAccepted width={'280px'} height={'244px'} />
                    <DText primary className={'mx-auto'}>
                        <Text tid={'user-already-doctor'} />
                    </DText>
                </>
                :
                <>
                    <DText primary>
                        <Text tid={'add-doctor-note'} />
                    </DText>

                    <div className={'flex flex-col gap-8'}>

                        <Background bg={'mainBg'}>
                            <Select
                                options={categories?.map(item => item.name) || []}
                                value={category?.name}
                                onValueChange={idx => setCategory(categories[idx])}
                                placeHolder={'select-category'}
                                width={'320px'}
                                height={'52px'}
                                size={'small'}
                            />
                        </Background>

                        <Background bg={'mainBg'}>
                            <Input
                                value={mobile}
                                label={'mobile'}
                                onInputChange={setMobile}
                                height={'52px'}
                                maxLength={11}
                                code
                            />
                        </Background>

                        <ActionButton
                            active={!!category && !!mobile}
                            onClick={onSubmitClicked}
                            loading={isLoading}
                        >
                            <Text tid='submit' />
                        </ActionButton>
                    </div>
                </>
            }
        </div>
    )
}

export default CreateDoctor