export const userAttributes = {
    cs: '5% 12% 12% 12% 10% 20% 10% 14% 5%',
    headers: [
        { title: 'fullname' },
        { title: 'idNo' },
        { title: 'mobile' },
        { title: 'balance', sort: 'balance' },
        { title: 'email' },
        { title: 'user-status' },
        { title: 'agent-status' }
    ]
}


export const transactionAttributes = {
    cs: '5% 15% 15% 15% 12% 12% 12% 9% 5%',
    headers: [
        { title: 'username' },
        { title: 'wallet' },
        { title: 'type' },
        { title: 'amount' },
        { title: 'before_balance' },
        { title: 'after_balance' },
        { title: 'date' },
    ]
}


export const stakingAttributes = {
    cs: '5% 15% 15% 15% 15% 15% 15% 5%',
    headers: [
        { title: 'coin' },
        { title: 'username' },
        { title: 'invest-name' },
        { title: 'balance' },
        { title: 'total_received' },
        { title: 'total_transactions' },
    ]
}



export const doctorAttributes = {
    cs: '5% 15% 20% 20% 20% 15% 5%',
    headers: [
        { title: 'doctor_id' },
        { title: 'full_name' },
        { title: 'email' },
        { title: 'specialization' },
        { title: 'mobile_number' }
    ]
}


export const timeSlotAttributes = {
    cs: '5% 15% 20% 20% 20% 10% 10%',
    headers: [
        { title: 'id' },
        { title: 'doctor' },
        { title: 'specialization' },
        { title: 'contact_type' },
        { title: 'date_time_slot' },
        { title: 'time_time_slot' },
    ]
}


export const specializationAttributes = {
    cs: '5% 20% 20% 50% 5%',
    headers: [
        { title: 'id' },
        { title: 'name' },
        { title: 'image' }
    ]
}

