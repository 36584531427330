import { Link } from "react-router-dom"
import { stakingAttributes } from "../../../../core/constants/headers"
import useGetTableIndex from "../../../../core/hooks/layout/useGetTableIndex"
import { Column, Row } from "../../../../styles/CommonStyles"
import TableLayout from "../../../layout/main/TableLayout"
import { formatNumber } from "../../../../core/utils/common"
import CoinRow from "../../../common/common/CoinRow"
import Text from "../../../../core/utils/Text"
import { useState } from "react"
import ModalLayout from "../../../layout/main/ModalLayout"
import StakingDetailModal from "./StakingDetailModal"


const StakingList = ({ data }) => {

    const { headers, cs } = stakingAttributes
    const { getTableIndex } = useGetTableIndex()

    const initModal = { show: false, data: null }
    const [modal, setModal] = useState(initModal)
    const openModal = data => setModal({ show: true, data })
    const closeModal = () => setModal(initModal)

    return (
        <TableLayout
            data={data}
            headers={headers}
            cs={cs}
        >
            {data?.data?.list?.map((item, idx) => {

                return (
                    <Row index={idx} cs={cs} key={item.id}>
                        <Column>
                            {getTableIndex(idx)}
                        </Column>
                        <Column>
                            <CoinRow coin={item.currency} />
                        </Column>
                        <Column>
                            <Link to={`/users/${item.user.id}`}>
                                <span className={'underline'}>{item.user.full_name}</span>
                            </Link>
                        </Column>
                        <Column>
                            <span>{item.name}</span>
                        </Column>
                        <Column>
                            <span>{formatNumber(item.balance, { type: item.currency.abbr })}</span>
                        </Column>
                        <Column>
                            <span>{formatNumber(item.total_received, { type: item.currency.abbr })}</span>
                        </Column>
                        <Column>
                            <span>{formatNumber(item.transactions_count, { type: 'irt' })}</span>
                        </Column>
                        <Column>
                            <div className={'cursor-pointer'} onClick={() => openModal(item)}>
                                <Text tid={'details'} className={'text-xs text-blue-500'} />
                            </div>
                        </Column>
                    </Row>
                )
            })}

            <ModalLayout
                open={modal.show}
                width={'480px'}
                onClose={closeModal}
                padding={0}
            >
                <StakingDetailModal data={modal.data} onClose={closeModal} />
            </ModalLayout>
        </TableLayout>
    )
}

export default StakingList