import { useState } from "react"
import ListLayout from "../../../components/layout/main/ListLayout"
import { CacheKeys, stakeOptions } from "../../../core/constants/filter"
import { PageCard } from "../../../styles/CommonStyles"
import { useWindowSize } from "../../../core/hooks/common/useWindowSize"
import { TABLET_SIZE } from "../../../core/constants/common"
import FilterLayout from "../../../components/layout/filter/FilterLayout"
import StakingList from "../../../components/main/reports/stakings/StakingList"
import RStakingList from "../../../components/main/reports/stakings/RStakingList"
import { useGetAllStakings } from "../../../core/services/react-query/report/staking"


const Staking = () => {

    const { width } = useWindowSize()
    const [stakes, setStakes] = useState({ loading: true, data: null })
    const Component = width > TABLET_SIZE ? StakingList : RStakingList

    return (
        <ListLayout>
            <PageCard>
                <FilterLayout
                    query={useGetAllStakings}
                    options={stakeOptions}
                    onQuerySuccess={setStakes}
                    cache={CacheKeys.stakings}
                >
                    <Component
                        data={stakes}
                    />
                </FilterLayout>
            </PageCard>
        </ListLayout>
    )
}

export default Staking