import { Link } from "react-router-dom"
import { transactionAttributes } from "../../../../core/constants/headers"
import useGetTableIndex from "../../../../core/hooks/layout/useGetTableIndex"
import { Column, Row } from "../../../../styles/CommonStyles"
import TableLayout from "../../../layout/main/TableLayout"
import { formatNumber } from "../../../../core/utils/common"
import DateTime from "../../../common/utils/DateTime"



const TransactionsList = ({ data }) => {

    const { headers, cs } = transactionAttributes
    const { getTableIndex } = useGetTableIndex()

    const typeBgs = {
        Deposit: 'bg-yellow-300',
        Visit: 'bg-blue-300'
    }

    return (
        <TableLayout
            data={data}
            headers={headers}
            cs={cs}
        >
            {data?.data?.list?.map((item, idx) => {

                return (
                    <Row index={idx} cs={cs} key={item.id}>
                        <Column>
                            {getTableIndex(idx)}
                        </Column>
                        <Column>
                            <Link to={`/users/${item.user.id}`}>
                                <span className={'underline'}>{
                                    `${item.user_full_name?.length > 2 ? item.user_full_name : '--'} 
                                    ${item.user.doctor_specialization?.name ?
                                        `(${item.user.doctor_specialization?.name})`
                                        : ''
                                    }`
                                }</span>
                            </Link>
                        </Column>
                        <Column>
                            <span>{item.wallet}</span>
                        </Column>
                        <Column>
                            <div className={`
                                rounded border-[1px] dark:border-gray-400 border-gray-600 border-opacity-50 px-10 py-2
                                ${typeBgs[item.type] || 'bg-green-500'} bg-opacity-20
                                `}>
                                <span>{item.type}</span>
                            </div>
                        </Column>
                        <Column>
                            <span>{formatNumber(item.amount, { type: 'usdt' })}</span>
                        </Column>
                        <Column>
                            <span>{formatNumber(item.before_balance, { type: 'usdt' })}</span>
                        </Column>
                        <Column>
                            <span className={'max-w-[100%] break-all'}>{formatNumber(item.after_balance, { type: 'usdt' })}</span>
                        </Column>
                        <Column>
                            <DateTime dt={item.created_at} />
                        </Column>
                    </Row>
                )
            })}
        </TableLayout>
    )
}

export default TransactionsList