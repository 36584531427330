import { useQuery } from "react-query"
import { filterFetch } from "../../../fetch-api/get"
import { useMainContext } from "../../../../contexts/main"
import { makeQueryFilters } from "../../../../utils/query-maker"

export const stakingKeys = {
    getAllStakings: 'getAllStakings'
}

export const useGetAllStakings = (filters, pagination, extra, csv) => {

    const { lang } = useMainContext()
    const newFilters = { ...extra, ...filters }
    const params = { ...makeQueryFilters(newFilters, lang), ...pagination }
    if (csv) {
        params.export = 'csv'
    }

    return useQuery({
        queryKey: [stakingKeys.getAllStakings],
        queryFn: () => filterFetch(params, 'wallets/admin/all-wallets/all-user-stakings'),
        select: res => res?.data?.data
    })
}