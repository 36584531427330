import styled from "styled-components"
import Text from "../../../core/utils/Text"
import { statusColors } from "../../../core/utils/theme"
import { useMemo } from "react"
import countries from "../../../core/constants/countries.json"


export const ProcessInput = ({ id, value, status, detail, changeStatus, ...rest }) => {

    const formattedValue = useMemo(() => {
        if (id === 'birthday') return (new Date(value * 1000)).toLocaleDateString()
        if (id === 'nationality') return countries.find(x => x.code === value)?.name || value

        return value
    }, [id, value])

    const onAcceptClick = () => {
        changeStatus(id, 'OK')
    }

    const onRejectClick = () => {
        changeStatus(id, 'NOK')
    }

    return (
        <div className={'flex flex-col gap-1 dark:text-gray-200'}>
            <Text tid={id} className={'text-sm'} />
            <ProcessInputWrapper
                className={'px-4 py-2 rounded-md shadow min-w-[300px] min-h-[48px] flex items-center justify-between'}
                statusColor={statusColors[status]}
                {...rest}
            >
                <span>{formattedValue}</span>
                {detail ?
                    <AcceptRejectBtns
                        status={status}
                        onAcceptClick={onAcceptClick}
                        onRejectClick={onRejectClick}
                    />
                    : null}
            </ProcessInputWrapper>
        </div>
    )
}

export const AcceptRejectBtns = ({ status, onAcceptClick, onRejectClick }) => {

    return (
        <div
            className={`
                h-[38px] w-[124px] flex gap-1
                `}
        >
            <div
                onClick={onAcceptClick}
                className={`
                            w-[72px] rounded-md flex items-center justify-center 
                            h-full border-[1px] border-slate-500 border-opacity-50
                            ${status === 'OK' ? 'bg-green-500' : ''} cursor-pointer
                        `}>
                <Text tid={'accept'} className={'text-sm'} />
            </div>
            <div
                onClick={onRejectClick}
                className={`
                            w-[72px] rounded-md flex items-center justify-center 
                            h-full border-[1px] border-slate-500 border-opacity-50
                            ${status === 'NOK' ? 'bg-red-500' : ''} cursor-pointer
                        `}>
                <Text tid={'reject'} className={'text-sm'} />
            </div>
        </div>
    )
}

export const ShowImageFullScreen = ({ showImage, image, onClose }) => {

    if (!image) return <></>

    return (
        <div
            onClick={onClose}
            className={'fixed left-0 top-0 flex items-center justify-center z-[100000] w-screen h-screen backdrop-blur-md bg-white/30'}>
            <img
                src={image.url}
                className={'w-[600px]'}
                alt={showImage}
            />
        </div>
    )
}

export const ProcessInputWrapper = styled.div`
    background-color: ${props => props.theme.mainBg};
    border: 1px solid ${props => props.statusColor || '#fafafa'}40;
`