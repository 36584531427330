import { Link } from "react-router-dom"
import Text from "../../../../core/utils/Text"
import CoinRow from "../../../common/common/CoinRow"
import { formatNumber } from "../../../../core/utils/common"



const StakingDetailModal = ({ data, onClose }) => {

    return (
        <div className={'dark:text-slate-200'}>
            <div className={'bg-slate-800 rounded-t-md w-full h-[52px] px-3 grid grid-cols-3 items-center'}>
                <CoinRow coin={data?.currency} />
                <Text tid={'staking-detail'} />
                <div />
            </div>
            <div className={'grid grid-cols-2 gap-3 p-4 text-sm'}>
                <Text tid={'username'} className={'dark:text-gray-400'} />
                <Link to={`/users/${data?.user.id}`}>
                    <span className={'underline'}>{data?.user.full_name}</span>
                </Link>
                <div className={'col-span-2 w-full h-[1px] bg-slate-500 bg-opacity-20'} />

                <Text tid={'balance'} className={'dark:text-gray-400'} />
                <span>{formatNumber(data?.balance, { type: data?.currency.abbr })}</span>
                <div className={'col-span-2 w-full h-[1px] bg-slate-500 bg-opacity-20'} />

                <Text tid={'total_received'} className={'dark:text-gray-400'} />
                <span>{formatNumber(data?.total_received, { type: data?.currency.abbr })}</span>
                <div className={'col-span-2 w-full h-[1px] bg-slate-500 bg-opacity-20'} />

                <Text tid={'total_spent'} className={'dark:text-gray-400'} />
                <span>{formatNumber(data?.total_spent, { type: data?.currency.abbr })}</span>
                <div className={'col-span-2 w-full h-[1px] bg-slate-500 bg-opacity-20'} />

                <Text tid={'total_transfer'} className={'dark:text-gray-400'} />
                <span>{formatNumber(data?.total_transfer, { type: data?.currency.abbr })}</span>
                <div className={'col-span-2 w-full h-[1px] bg-slate-500 bg-opacity-20'} />

                <Text tid={'transfers_count'} className={'dark:text-gray-400'} />
                <span>{formatNumber(data?.transfers_count, { type: 'irt' })}</span>
                <div className={'col-span-2 w-full h-[1px] bg-slate-500 bg-opacity-20'} />

                <Text tid={'transactions_count'} className={'dark:text-gray-400'} />
                <span>{formatNumber(data?.transactions_count, { type: 'irt' })}</span>
            </div>

            <div className={'bg-blue-500 rounded-md cursor-pointer h-[42px] w-[90%] mx-auto mb-3 flex items-center justify-center text-xs'} onClick={onClose}>
                <Text tid={'close'} />
            </div>
        </div>
    )
}

export default StakingDetailModal