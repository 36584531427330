import { useNavigate } from "react-router-dom";
import { acceptUserOperations } from "../../../constants/operation";


const useUsersList = () => {


    const navigate = useNavigate()


    const onOptionClicked = (idx, user) => {
        let action;
        if (idx === 0) {
            if (user?.verifyAt) action = 'reject'
            else action = 'accept'
        } else {
            action = acceptUserOperations[idx]
        }
        navigate(`/users/${user?.id}`, { state: { action } })
    }

    const onDetailsClicked = (user) => {
        navigate(`/users/${user?.id}`, { state: { user } })
    }

    return {
        onOptionClicked,
        onDetailsClicked
    }
}

export default useUsersList
