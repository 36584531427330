import { specializationAttributes } from "../../../../core/constants/headers"
import { Column, Row } from "../../../../styles/CommonStyles"
import TableLayout from "../../../layout/main/TableLayout"


const SpecializationList = ({ data }) => {

    const { cs, headers } = specializationAttributes

    return (
        <div className={'mt-5'}>
            <TableLayout
                cs={cs}
                headers={headers}
                data={data}
                hasPagination={false}
            >
                {data?.data?.list?.map((item, idx) => {

                    return (
                        <Row key={item.id} index={idx} cs={cs}>
                            <Column>
                                {idx + 1}
                            </Column>

                            <Column>
                                {item.id}
                            </Column>

                            <Column>
                                {item.name}
                            </Column>

                            <Column>
                                <img
                                    src={item.images[0]?.url}
                                    alt={item.images[0]?.name}
                                    width={42}
                                    height={42}
                                />
                            </Column>
                        </Row>
                    )
                })}
            </TableLayout>
        </div>
    )
}

export default SpecializationList