import { useNavigate } from "react-router-dom"
import Text from "../../../../core/utils/Text"
import { Relative, Row } from "../../../../styles/CommonStyles"
import Operation from "../../../common/utils/Operation"
import RTableLayout from "../../../responsive/layout/RTableLayout"


const RDoctorsList = ({ data }) => {

    const navigate = useNavigate()
    const onDetailsClicked = (doctor) => {
        navigate(
            `/medical/doctors/${doctor.user.doctor_id}`
        )
    }

    return (
        <RTableLayout
            data={data}
        >
            {data?.data?.list?.map((item, idx) => {

                return (
                    <Relative key={item.id}>
                        <Row index={idx} cs={'100%'}>
                            <div className={'grid grid-cols-2 gap-3'}>
                                <div />
                                <Operation
                                    onDetailsClicked={() => onDetailsClicked(item)}
                                    hasDetails
                                />

                                <Text tid={'doctor_id'} />
                                <span>{item.user.doctor_id}</span>
                                <div className={'col-span-2 mx-auto w-full bg-opacity-20 h-[1px] bg-gray-500'} />

                                <Text tid={'full_name'} />
                                <span>{item.user.full_name}</span>
                                <div className={'col-span-2 mx-auto w-full bg-opacity-20 h-[1px] bg-gray-500'} />

                                <Text tid={'email'} />
                                <span>{item.user.email}</span>
                                <div className={'col-span-2 mx-auto w-full bg-opacity-20 h-[1px] bg-gray-500'} />

                                <Text tid={'specialization'} />
                                <div className={'flex items-center gap-2'}>
                                    <img
                                        src={item.specialization.images[0].url}
                                        width={52}
                                        height={52}
                                        alt={item.specialization.name}
                                    />
                                    <span>{item.specialization.name}</span>
                                </div>
                                <div className={'col-span-2 mx-auto w-full bg-opacity-20 h-[1px] bg-gray-500'} />

                                <Text tid={'mobile_number'} />
                                <span>{item.user.mobile_number}</span>
                            </div>
                        </Row>
                    </Relative>
                )
            })}
        </RTableLayout>
    )
}

export default RDoctorsList