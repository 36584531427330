import Input from '../../../components/common/input/Input'
import ListLayout from '../../../components/layout/main/ListLayout'
import DoctorFees from './DoctorFees'

const DoctorSetting = () => {
	return (
		<ListLayout>
			<div className={'w-full grid grid-cols-3'}>
				<DoctorFees />
			</div>
		</ListLayout>
	)
}

export default DoctorSetting
