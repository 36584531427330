import { FaExpeditedssl, FaPlus } from 'react-icons/fa6'
import ListLayout from '../../../components/layout/main/ListLayout'
import {
	useGetCurrencyStakingSetting,
	useUpdateCurrencyStakingSetting,
} from '../../../core/services/react-query/setting/staking'
import Text from '../../../core/utils/Text'
import { useEffect, useState } from 'react'
import ModalLayout from '../../../components/layout/main/ModalLayout'
import { useGetCurrencies } from '../../../core/services/react-query/setting/currencies'
import Select from '../../../components/common/dropdown/Select'
import { ClipLoader } from 'react-spinners'
import { getMainTheme } from '../../../core/utils/theme'
import Input from '../../../components/common/input/Input'
import { FaEdit, FaRegEdit } from 'react-icons/fa'
import { stringToNumber } from '../../../core/utils/common'

const Staking = () => {
	const { data: stakeSetting, isLoading: gettingStake } = useGetCurrencyStakingSetting()

	const initModal = { show: false, data: null }
	const [modal, setModal] = useState(initModal)
	const openModal = (data) => setModal({ show: true, data })
	const closeModal = () => setModal(initModal)

	const { mutate: updateStake, isLoading: updatingStake } =
		useUpdateCurrencyStakingSetting(closeModal)
	const { data: currencies } = useGetCurrencies()

	const [currency, setCurrency] = useState(null)
	const [pc, setPc] = useState('')

	const randomBgColor = (idx) => {
		const colors = ['bg-green-500', 'bg-orange-500', 'bg-blue-500', 'bg-yellow-500', 'bg-red-500']
		return colors[idx % 5]
	}

	const onUpdateStake = () => {
		updateStake({ abbr: currency.abbr, percent: stringToNumber(pc) })
	}

	useEffect(() => {
		if (modal.data) {
			const curr = currencies?.list?.find((x) => x.abbr === modal.data.abbr)
			setCurrency(curr)
			setPc(modal.data.percent)
		} else {
			setCurrency(null)
			setPc('')
		}
	}, [modal, currencies])

	return (
		<ListLayout className={'relative'}>
			{gettingStake ? (
				<div
					className={
						'h-[400px] backdrop-blur-sm absolute left-0 top-0 w-full flex items-center justify-center'
					}
				>
					<ClipLoader size={24} color={getMainTheme().active} />
				</div>
			) : null}
			<div className={'grid grid-cols-5 gap-5 w-full'}>
				{stakeSetting?.map((item, idx) => {
					let imgSrc
					try {
						const src = currencies?.list?.find((x) => x.abbr === item.abbr)
						imgSrc = src.images[0].url
					} catch (err) {}

					return (
						<div
							className={`
                            rounded-md border-[1px] border-gray-500 border-opacity-20
                            dark:bg-bgDarkPrimary w-full h-[184px]
                        `}
							key={item.abbr}
						>
							<div
								className={`h-[46px] w-full rounded-t-md bg-opacity-20 ${randomBgColor(
									idx
								)} flex items-center justify-center`}
							>
								{!!imgSrc ? (
									<img src={imgSrc} width={32} height={32} className={''} alt={imgSrc} />
								) : null}
							</div>
							<div className={'py-6 dark:text-gray-200 px-4 flex flex-col gap-3'}>
								<div className={'flex items-center justify-between gap-2'}>
									<span>
										<Text tid={'currency'} />
										<span>: </span>
									</span>
									<span>{item.abbr.toUpperCase()}</span>
								</div>
								<div className={'flex items-center justify-between gap-2'}>
									<span>
										<Text tid={'percent'} />
										<span>: </span>
									</span>
									<span dir={'ltr'}>{item.percent} %</span>
								</div>
								<div
									className={`bg-blue-500 flex items-center justify-center 
                                        gap-2 bg-opacity-60 hover:bg-opacity-100 transition 
                                        cursor-pointer rounded-md w-full h-[32px] text-sm`}
									onClick={() => openModal(item)}
								>
									<FaRegEdit size={18} />
									<Text tid={'edit'} />
								</div>
							</div>
						</div>
					)
				})}

				<div
					className={`dark:text-gray-500 cursor-pointer w-full flex items-center 
                    justify-center h-[172px] rounded-md border-[1px] border-dashed
                     border-opacity-20 border-gray-500`}
					onClick={openModal}
				>
					<FaPlus size={28} />
				</div>
			</div>

			<ModalLayout open={modal.show} width={'480px'} onClose={closeModal}>
				<div className={'flex flex-col gap-4 dark:text-gray-300'}>
					<Text tid={'add-staking-currency'} />
					<div className={'flex flex-col gap-1'}>
						<Text tid={'currency'} className={'text-sm'} />
						<Select
							options={currencies?.list || []}
							value={currency}
							onValueChange={(idx) => setCurrency(currencies.list[idx])}
							size={'small'}
							isCoin
						/>
					</div>
					<div className={'flex flex-col gap-1 mt-3'}>
						<Text tid={'percent'} className={'text-sm'} />
						<Input value={pc} onInputChange={setPc} code />
					</div>

					<div
						className={
							'bg-blue-500 cursor-pointer text-black rounded-md flex items-center justify-center w-full h-[42px]'
						}
						onClick={onUpdateStake}
					>
						{updatingStake ? (
							<ClipLoader size={24} color={getMainTheme().active} />
						) : (
							<Text tid={'submit'} />
						)}
					</div>
				</div>
			</ModalLayout>
		</ListLayout>
	)
}

export default Staking
