import { Link, useNavigate } from "react-router-dom"
import Text from "../../../../core/utils/Text"
import { Relative, Row } from "../../../../styles/CommonStyles"
import Operation from "../../../common/utils/Operation"
import RTableLayout from "../../../responsive/layout/RTableLayout"
import { formatDate } from "../../../../core/utils/common"
import { useMainContext } from "../../../../core/contexts/main"


const RTimeSlotsList = ({ data, hasPagination = true }) => {

    const { lang } = useMainContext()
    const navigate = useNavigate()
    const typeBgs = {
        phone: 'bg-purple-300',
        visit: 'bg-blue-300',
        chat: 'bg-red-300',
        'online-video-call': 'bg-gray-100'
    }


    return (
        <RTableLayout
            data={data}
            hasPagination={hasPagination}
        >
            {data?.data?.list?.map((item, idx) => {

                return (
                    <Relative key={item.id}>
                        <Row index={idx} cs={'100%'}>
                            <div className={'grid grid-cols-2 gap-3'}>


                                <Text tid={'doctor'} />
                                <Link to={`/users/${item.doctor.user.id}`}>
                                    <span className={'underline textMain'}>{`${item.doctor.user.full_name} (${item.doctor.user.id})`}</span>
                                </Link>
                                <div className={'col-span-2 mx-auto w-full bg-opacity-20 h-[1px] bg-gray-500'} />

                                <Text tid={'specialization'} />
                                <div className={'flex items-center gap-2'}>
                                    <img
                                        src={item.doctor.specialization.images[0].url}
                                        width={52}
                                        height={52}
                                        alt={item.doctor.specialization.name}
                                    />
                                    <span>{item.doctor.specialization.name}</span>
                                </div>
                                <div className={'col-span-2 mx-auto w-full bg-opacity-20 h-[1px] bg-gray-500'} />

                                <Text tid={'contact_type'} />
                                <div className={`
                                    rounded border-[1px] dark:border-gray-400 border-gray-600 border-opacity-50 px-10 py-2
                                    ${typeBgs[item.contact_type] || 'bg-green-500'} bg-opacity-20 w-min
                                    `}>
                                    <span>{item.contact_type}</span>
                                </div>
                                <div className={'col-span-2 mx-auto w-full bg-opacity-20 h-[1px] bg-gray-500'} />

                                <Text tid={'date'} />
                                <span dir={'ltr w-min textMain'}>
                                    {`${formatDate(new Date(item.available_date), 'date', lang)} ${item.start_time.slice(0, 5)}`}
                                </span>

                            </div>
                        </Row>
                    </Relative>
                )
            })}
        </RTableLayout>
    )
}

export default RTimeSlotsList