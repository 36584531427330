import ListLayout from "../../components/layout/main/ListLayout";
import { Background, Decoration, DText, Flex, Padding, PageCard } from "../../styles/CommonStyles";
import { fadeVariants } from "../../core/utils/theme";
import Text from "../../core/utils/Text";
import { ProfileInfoAnimate, ProfileInfoItem } from "../../styles/main/profile";
import { TextArea } from "../../styles/main/user";
import Input from "../../components/common/input/Input";
import ActionButton from "../../components/common/buttons/ActionButton";
import { useState, useEffect } from "react";
import { useMainContext } from "../../core/contexts/main";
import { useQueryContext } from "../../core/contexts/query";
import { useCreateUser } from "../../core/services/react-query/user";
import { inputLength, numberInputTypes, validateProfileInfo } from "../../core/utils/validation";
import { formatAuthDate } from "../../core/utils/common";
import DatePicker from "react-multi-date-picker";
import persian from "react-date-object/calendars/persian";
import persian_fa from "react-date-object/locales/persian_fa";
import transition from "react-element-popper/animations/transition";
import DateInput from "../../components/common/input/DateInput";
import { MOBILE_SIZE } from "../../core/constants/common";
import { useWindowSize } from "../../core/hooks/common/useWindowSize";
import { useMemo } from "react";


const profileInfoInitial = {
    email: '',
    username: '',
    first_name: '',
    last_name: '',
    password: '',
}

const validationStatusInitial = {
    email: { valid: false, error: 'email-format-error' },
    first_name: { valid: false, error: 'input-length-error' },
    last_name: { valid: false, error: 'input-length-error' },
    username: { valid: false, error: 'input-length-error' },
    password: { valid: false, error: 'input-length-error' },
}


const AddUser = () => {

    const { width } = useWindowSize()
    const { lang, theme } = useMainContext()
    const { setToast } = useQueryContext()

    const { mutate: createUser, isSuccess, isLoading } = useCreateUser()

    const [profileInfo, setProfileInfo] = useState(profileInfoInitial)

    const [validationStatus, setValidationStatus] = useState(validationStatusInitial)

    const valid = useMemo(() => {
        return validationStatus?.email?.valid && validationStatus?.password?.valid
    }, [validationStatus])

    const onInputValueChange = (key, value) => {

        setProfileInfo(state => {
            const newState = { ...state }
            newState[key] = value
            return newState
        })

        setValidationStatus(validateProfileInfo(key, value, validationStatus))
    }

    const getNewProfilePayload = () => {
        const newProfile = {}
        newProfile.first_name = profileInfo.first_name
        newProfile.last_name = profileInfo.last_name
        newProfile.email = profileInfo.email
        newProfile.username = profileInfo.username
        newProfile.password = profileInfo.password
        newProfile.password_confirmation = profileInfo.password

        return newProfile
    }

    const onCreateUser = () => {
        if (valid) {
            createUser(getNewProfilePayload())
        } else {
            setToast({
                show: true, isError: true,
                message: 'fill-input-errors'
            })
        }
    }

    useEffect(() => {
        if (isSuccess) {
            setProfileInfo(profileInfoInitial)
        }
    }, [isSuccess])



    return (
        <ListLayout>
            <PageCard>
                <ProfileInfoAnimate
                    variants={fadeVariants}
                    animate='in'
                    exit='out'
                    initial='out'
                >
                    <Padding padding={'20px'}>
                        <div className={'w-full gap-x-12 gap-y-2 grid grid-cols-2'}>
                            {profileInfo &&
                                Object.keys(profileInfo).map(key => {
                                    const value = profileInfo[key]

                                    return (
                                        <Background
                                            bg={'mainBg'}
                                            key={key}
                                        >
                                            {key === 'birthDate' &&
                                                <DatePicker
                                                    calendar={lang === 'fa' && persian}
                                                    locale={lang === 'fa' && persian_fa}
                                                    value={value}
                                                    onChange={(v) => onInputValueChange(key, v)}
                                                    animations={[transition()]}
                                                    render={<DateInput isProfile label={'birthDate'} />}
                                                    className={`${theme === 'dark' && 'bg-dark custom-calendar'} teal ${width < MOBILE_SIZE && 'rmdp-mobile'}`}
                                                    calendarPosition={lang === 'fa' ? 'left-top' : 'right-top'}
                                                />
                                            }

                                            {key === 'address' &&
                                                <TextArea
                                                    width={'calc(100% - 14px)'}
                                                    minHeight={'40px'}
                                                    value={value}
                                                    placeholder={lang === 'en' ? 'address' : 'آدرس'}
                                                    onChange={(e) => onInputValueChange(key, e?.target?.value)}
                                                />
                                            }
                                            {(key !== 'role' && key !== 'address' && key !== 'birthDate') &&
                                                <Input
                                                    value={value}
                                                    label={key}
                                                    onInputChange={(v) => onInputValueChange(key, v)}
                                                    valid={validationStatus[key].valid}
                                                    error={!!value && validationStatus[key].error}
                                                    code={numberInputTypes.includes(key)}
                                                    maxLength={inputLength[key]}
                                                    height={'52px'}
                                                />
                                            }

                                        </Background>
                                    )
                                })
                            }

                        </div>
                        <Flex fw>
                            <Flex width='280px' style={{ margin: '30px 0' }}>
                                <ActionButton
                                    active={valid}
                                    onClick={onCreateUser}
                                    loading={isLoading}
                                >
                                    <Text tid={'create-user'} />
                                </ActionButton>
                            </Flex>
                        </Flex>

                    </Padding>
                    <Flex fw wrap justify={'space-around'}>

                    </Flex>
                </ProfileInfoAnimate>
            </PageCard>
        </ListLayout>
    )
}


export default AddUser
