import { useState } from "react"
import FilterLayout from "../../../components/layout/filter/FilterLayout"
import ListLayout from "../../../components/layout/main/ListLayout"
import { useGetAllTimeSlots } from "../../../core/services/react-query/medical"
import { PageCard } from "../../../styles/CommonStyles"
import { CacheKeys, timeSlotOptions } from "../../../core/constants/filter"
import { useWindowSize } from "../../../core/hooks/common/useWindowSize"
import { TABLET_SIZE } from "../../../core/constants/common"
import TimeSlotsList from "../../../components/main/medical/time_slots/TimeSlotsList"
import RTimeSlotsList from "../../../components/main/medical/time_slots/RTimeSlotsList"



const TimeSlots = () => {

    const { width } = useWindowSize()
    const [data, setData] = useState({ loading: true, data: null })
    const Component = width > TABLET_SIZE ? TimeSlotsList : RTimeSlotsList

    return (
        <ListLayout>
            <PageCard>
                <FilterLayout
                    query={useGetAllTimeSlots}
                    cache={CacheKeys.time_slots}
                    onQuerySuccess={setData}
                    options={timeSlotOptions}
                >
                    <Component data={data} />
                </FilterLayout>
            </PageCard>
        </ListLayout>
    )
}

export default TimeSlots