
import { FaPlus } from "react-icons/fa6"
import ModalLayout from "../../../layout/main/ModalLayout"
import Text from "../../../../core/utils/Text"
import { useUploadImage } from "../../../../core/services/react-query/setting"
import { useMemo, useRef, useState } from "react"
import Input from "../../../common/input/Input"
import { Background, DText } from "../../../../styles/CommonStyles"
import ActionButton from "../../../common/buttons/ActionButton"
import { useCreateSpecialization } from "../../../../core/services/react-query/user"

const AddSpecializationModal = ({ open, onClose }) => {


    const imgRef = useRef()

    const [imgObj, setImg] = useState({})
    const [name, setName] = useState('')

    const onUploadSuccess = (res) => {
        setImg(res?.data?.data[0])
    }
    const { mutate: uploadImage } = useUploadImage(onUploadSuccess)

    const onUploadImage = () => {
        const file = imgRef.current?.files[0]
        if (!!file) {
            const formData = new FormData()
            formData.append('files[]', file)
            uploadImage(formData)
        }
    }

    const validAction = useMemo(() => {
        return !!imgObj?.id && !!name
    }, [imgObj, name])


    const onCreateSuccess = () => {
        onClose()
    }
    const { mutate: createCategory, isLoading } = useCreateSpecialization(onCreateSuccess)

    const onSubmit = () => {
        if (!validAction) return
        createCategory({
            file_id: imgObj.id,
            name
        })
    }

    return (
        <ModalLayout
            open={open}
            onClose={onClose}
            width={'480px'}
        >
            <div className={'flex flex-col gap-5'}>
                <DText primary>
                    <Text tid={'add-specialization'} />
                </DText>
                <div className={`
                    rounded-md border-[1px] border-dashed flex items-center justify-center
                    w-full py-2 min-h-[42px] border-gray-500 border-opacity-20 gap-2 text-sm 
                    dark:text-gray-400 cursor-pointer relative
                `}>

                    {!!imgObj?.id ?
                        <img
                            src={imgObj.url}
                            alt={imgObj.name}
                            width={64}
                            height={64}
                        />
                        :
                        <>
                            <FaPlus size={16} />
                            <Text tid={'add-image'} />
                        </>
                    }

                    <input
                        type={'file'}
                        className={'absolute opacity-0 left-0 top-0 w-full h-full'}
                        onChange={onUploadImage}
                        ref={imgRef}
                    />
                </div>

                <Background bg={'mainBg'}>
                    <Input
                        value={name}
                        onInputChange={setName}
                        label={'specialization-name'}
                    />
                </Background>

                <ActionButton
                    loading={isLoading}
                    active={validAction}
                    onClick={onSubmit}
                >
                    <Text tid={'submit'} />
                </ActionButton>
            </div>
        </ModalLayout>
    )
}

export default AddSpecializationModal