import { Link } from "react-router-dom";
import Text from "../../../../core/utils/Text";
import { Row } from "../../../../styles/CommonStyles";
import RTableLayout from "../../../responsive/layout/RTableLayout";
import { formatDate, formatNumber } from "../../../../core/utils/common";
import { useMainContext } from "../../../../core/contexts/main";
import CoinRow from "../../../common/common/CoinRow";


const RStakingList = ({ data }) => {

    const { lang } = useMainContext()
    const typeBgs = {
        Deposit: 'bg-yellow-300',
        Visit: 'bg-blue-300'
    }


    return (
        <RTableLayout
            data={data}
        >
            {data?.data?.list?.map((item, idx) => {
                return (
                    <Row index={idx} cs={'100%'} key={item.id}>
                        <div className={'grid grid-cols-2 gap-3'}>
                            <CoinRow coin={item?.currency} />
                            <div />
                            <Text tid={'username'} className={'dark:text-gray-400'} />
                            <Link to={`/users/${item?.user.id}`}>
                                <span className={'underline'}>{item?.user.full_name}</span>
                            </Link>
                            <div className={'col-span-2 w-full h-[1px] bg-slate-500 bg-opacity-20'} />

                            <Text tid={'balance'} className={'dark:text-gray-400'} />
                            <span>{formatNumber(item?.balance, { type: item?.currency.abbr })}</span>
                            <div className={'col-span-2 w-full h-[1px] bg-slate-500 bg-opacity-20'} />

                            <Text tid={'total_received'} className={'dark:text-gray-400'} />
                            <span>{formatNumber(item?.total_received, { type: item?.currency.abbr })}</span>
                            <div className={'col-span-2 w-full h-[1px] bg-slate-500 bg-opacity-20'} />

                            <Text tid={'total_spent'} className={'dark:text-gray-400'} />
                            <span>{formatNumber(item?.total_spent, { type: item?.currency.abbr })}</span>
                            <div className={'col-span-2 w-full h-[1px] bg-slate-500 bg-opacity-20'} />

                            <Text tid={'total_transfer'} className={'dark:text-gray-400'} />
                            <span>{formatNumber(item?.total_transfer, { type: item?.currency.abbr })}</span>
                            <div className={'col-span-2 w-full h-[1px] bg-slate-500 bg-opacity-20'} />

                            <Text tid={'transfers_count'} className={'dark:text-gray-400'} />
                            <span>{formatNumber(item?.transfers_count, { type: 'irt' })}</span>
                            <div className={'col-span-2 w-full h-[1px] bg-slate-500 bg-opacity-20'} />

                            <Text tid={'transactions_count'} className={'dark:text-gray-400'} />
                            <span>{formatNumber(item?.transactions_count, { type: 'irt' })}</span>
                        </div>
                    </Row>
                )
            })}
        </RTableLayout>
    )
}

export default RStakingList