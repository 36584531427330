import { useMutation, useQuery } from "react-query"
import { filterFetch, normalFetch } from "../../fetch-api/get"
import { useMainContext } from "../../../contexts/main"
import { makeQueryFilters } from "../../../utils/query-maker"
import { postApi } from "../../fetch-api/post"
import { useQueryContext } from "../../../contexts/query"



export const medicalKeys = {
    getAllDoctors: 'getAllDoctors',
    getDoctor: 'getDoctor',
    updateTypePrice: 'updateTypePrice',
    getContactTypes: 'getContactTypes',
    getAllTimeSlots: 'getAllTimeSlots',
    getDoctorTimeSlots: 'getDoctorTimeSlots',
    getDoctorFees: 'getDoctorFees',
    updateDoctorFee: 'updateDoctorFee'
}


export const useGetAllDoctors = (filters, pagination, extra, csv) => {

    const { lang } = useMainContext()
    const newFilters = { ...extra, ...filters }
    const params = { ...makeQueryFilters(newFilters, lang), ...pagination }
    if (csv) {
        params.export = 'csv'
    }


    return useQuery({
        queryFn: () => filterFetch(params, 'medical/admin/doctors'),
        queryKey: [medicalKeys.getAllDoctors],
        select: res => res?.data?.data
    })
}


export const useGetADoctor = (id) => {

    return useQuery({
        queryFn: () => normalFetch(`medical/${id}/details`),
        queryKey: [medicalKeys.getDoctor, id],
        select: res => res?.data?.data,
        enabled: !!id
    })
}


export const useGetDoctorTimeSlots = (id) => {

    return useQuery({
        queryFn: () => normalFetch(`medical/${id}/time-slots`),
        queryKey: [medicalKeys.getDoctorTimeSlots, id],
        select: res => res?.data?.data,
        enabled: !!id
    })
}


export const useUpdateTypePrice = (onSuccess) => {

    return useMutation({
        mutationKey: [medicalKeys.updateTypePrice],
        mutationFn: ({ payload, doctor_id }) => postApi(payload, `medical/admin/doctors/${doctor_id}/update-contact-type-price`),
        onSuccess
    })
}


export const useGetContactTypes = () => {

    return useQuery({
        queryFn: () => normalFetch(`medical/contact-types`),
        queryKey: [medicalKeys.getContactTypes],
        select: res => res?.data?.data,
    })
}

export const useGetAllTimeSlots = (filters, pagination, extra, csv) => {

    const { lang } = useMainContext()
    const newFilters = { ...extra, ...filters }
    const params = { ...makeQueryFilters(newFilters, lang), ...pagination }
    if (csv) {
        params.export = 'csv'
    }


    return useQuery({
        queryFn: () => filterFetch(params, 'medical/admin/time-slots'),
        queryKey: [medicalKeys.getAllTimeSlots],
        select: res => res?.data?.data
    })
}


export const useGetDoctorFees = () => {

    return useQuery({
        queryFn: () => normalFetch(`medical/contact_type_fees`),
        queryKey: [medicalKeys.getDoctorFees],
        select: res => res?.data?.data,
    })
}


export const useUpdateDoctorFees = () => {

    const { queryClient, setToast } = useQueryContext()

    return useMutation({
        mutationKey: [medicalKeys.updateDoctorFee],
        mutationFn: (payload) => postApi(payload, `medical/admin/contact_type_fee`),
        onSuccess: () => {
            setToast({
                show: true,
                message: "operation-success"
            })
            queryClient.invalidateQueries([medicalKeys.getDoctorFees])
        }
    })
}