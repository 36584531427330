import { ItemWrapper } from '../../../styles/layout/filter'
import Text from '../../../core/utils/Text'
import { useEffect, useRef, useState } from 'react'
import { CloseIcon, OpenIcon } from '../../../styles/components/common/select-styles'
import useClickOutside from '../../../core/hooks/common/useClickOutside'
import FilterBox from './FilterBox'
import { Flex } from '../../../styles/CommonStyles'

const FilterItem = (props) => {
	const { prefix, suffix, options } = props
	const filter = options.find((item) => item.search === prefix)

	const ref = useRef()

	const [filterItem, setFilterItem] = useState({ name: '', search: '' })

	useEffect(() => {
		console.log({ filter })
		switch (prefix) {
			case 'firstName':
				setFilterItem({ name: 'firstName', search: 'name' })
				break
			case 'lastName':
				setFilterItem({ name: 'lastName', search: 'name' })
				break
			case 'dateFrom':
				setFilterItem({ name: 'dateFrom', search: 'date' })
				break
			case 'dateTo':
				setFilterItem({ name: 'dateTo', search: 'date' })
				break
			default:
				setFilterItem(filter)
		}
	}, [prefix])

	const [open, setOpen] = useState(false)
	const onClose = () => {
		if (open) setOpen(false)
	}
	useClickOutside(ref, onClose)

	if (prefix === 'defaults') {
		return <></>
	}

	return (
		<ItemWrapper ref={ref}>
			<Flex onClick={() => setOpen((state) => !state)} style={{ cursor: 'pointer' }}>
				<Text tid={filterItem?.name} /> :
				<Text style={{ margin: '0 2px' }} tid={suffix} />
				{open ? <CloseIcon size={22} /> : <OpenIcon size={22} />}
			</Flex>

			{open && <FilterBox type={'update'} filter={filterItem} onClose={onClose} />}
		</ItemWrapper>
	)
}

export default FilterItem
