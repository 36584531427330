import styled, { css } from "styled-components";

export const NavWrapper = styled.div`
  position: absolute;
  top: 80px;
  height: 60px;
  z-index: 99;
  background-color: ${props => props.theme.primaryBg};
  transition: all 0.3s;
  ${props => props.theme.english && css`
    left: ${props => props.open ? '280px' : '140px'};
    width: calc(100% - ${props => props.open ? '280px' : '140px'});
  `};
  ${props => !props.theme.english && css`
    right: ${props => props.open ? '280px' : '140px'};
    width: calc(100% - ${props => props.open ? '280px' : '140px'});
  `};
  display: flex;
  direction: ${props => props.theme.english ? 'ltr' : 'rtl'};
  flex-wrap: wrap;
  align-items: center;
  
  @media screen and (max-width: 768px) {
    ${props => props.theme.english && css`
      left: 5px;
    `};
    ${props => !props.theme.english && css`
      right: 5px;
    `};
    width: calc(100% - 5px);
  };
`
