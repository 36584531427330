

const RSpecializationList = () => {

    return (
        <div>

        </div>
    )
}

export default RSpecializationList