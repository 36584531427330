import { UserActionWrapper, UserDetailListWrapper, UserSettingBtn } from "../../../../../styles/main/user";
import { Flex } from "../../../../../styles/CommonStyles";
import Text from "../../../../../core/utils/Text";
import { useState } from "react";
import AcceptUser from "./AcceptUser";
import RejectUser from "./RejectUser";
import { useLocation } from "react-router-dom";
import CreateDoctor from "./CreateDoctor";
import ChangeBalance from "./ChangeBalance";


const UserSetting = (props) => {

    const location = useLocation()

    const navigateActions = {
        accept: 0,
        reject: 1,
        'increase-balance': 2,
        'decrease-balance': 3
    }

    const [active, setActive] = useState(
        location.state ? navigateActions[location.state.action] : -1
    )

    const actions = [
        { name: 'accept-user', color: '#819F82' },
        { name: 'reject-user', color: '#9F819E' },
        { name: 'change-balance', color: '#6f75ab' },
        { name: 'create-doctor', color: '#819F82' }
    ]

    const comps = [
        AcceptUser,
        RejectUser,
        ChangeBalance,
        CreateDoctor
    ]

    const UserAction = comps[active]

    return (
        <UserDetailListWrapper>
            <Flex fw wrap>
                {actions.map((action, idx) => (
                    <UserSettingBtn
                        color={action.color}
                        onClick={() => setActive(idx)}
                        active={idx === active}
                    >
                        <Flex fw fh>
                            <Text tid={action.name} />
                        </Flex>
                    </UserSettingBtn>
                ))}
            </Flex>
            {active !== -1 &&
                <UserActionWrapper>
                    <UserAction
                        {...props}
                        changeType={active === 2 ? 'INCREASE' : 'DECREASE'}
                    />
                </UserActionWrapper>
            }
        </UserDetailListWrapper>
    )
}

export default UserSetting
