import gregorian from "react-date-object/calendars/gregorian";
import gregorian_en from "react-date-object/locales/gregorian_en";
import persian from "react-date-object/calendars/persian";
import persian_fa from "react-date-object/locales/persian_fa";
import { deepCopy } from "./common";


export const makeQueryFilters = (filters, lang) => {
    let payload = deepCopy(filters)
    console.log(payload)
    if (payload.dateFrom) {
        const d = new Date(payload.dateFrom)
        payload.date_from = `${d.getDate()}/${d.getMonth() + 1}/${d.getFullYear()}`
        delete payload.dateFrom
    }
    if (payload.dateTo) {
        const d = new Date(payload.dateTo)
        payload.date_to = `${d.getDate()}/${d.getMonth() + 1}/${d.getFullYear()}`
        delete payload.dateTo
    }
    if (payload.time_slot_date) {
        const d = new Date(payload.time_slot_date)
        payload.time_slot_date = `${d.getDate()}/${d.getMonth() + 1}/${d.getFullYear()}`
    }
    return payload
}
