import { useQuery } from "react-query"
import { filterFetch } from "../../../fetch-api/get"
import { useMainContext } from "../../../../contexts/main"
import { makeQueryFilters } from "../../../../utils/query-maker"


export const transactionKeys = {
    getAllTransactions: 'getAllTransactions'
}


export const useGetAllTransactions = (filters, pagination, extra, csv) => {

    const { lang } = useMainContext()
    const newFilters = { ...extra, ...filters }
    const params = { ...makeQueryFilters(newFilters, lang), ...pagination }
    if (csv) {
        params.export = 'csv'
    }

    return useQuery({
        queryKey: [transactionKeys.getAllTransactions],
        queryFn: () => filterFetch(params, 'wallets/admin/users/all-transactions'),
        select: res => res?.data?.data
    })
}