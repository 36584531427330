import { useState } from "react"
import FilterLayout from "../../../components/layout/filter/FilterLayout"
import ListLayout from "../../../components/layout/main/ListLayout"
import { CacheKeys, doctorsOptions } from "../../../core/constants/filter"
import { useGetAllDoctors } from "../../../core/services/react-query/medical"
import { PageCard } from "../../../styles/CommonStyles"
import { useWindowSize } from "../../../core/hooks/common/useWindowSize"
import { TABLET_SIZE } from "../../../core/constants/common"
import DoctorsList from "../../../components/main/medical/doctors/DoctorsList"
import RDoctorsList from "../../../components/main/medical/doctors/RDoctorsList"


const Doctors = () => {

    const { width } = useWindowSize()
    const [data, setData] = useState({ data: null, loading: true })
    const Component = width > TABLET_SIZE ? DoctorsList : RDoctorsList

    return (
        <ListLayout>
            <PageCard>
                <FilterLayout
                    query={useGetAllDoctors}
                    cache={CacheKeys.doctors}
                    onQuerySuccess={setData}
                    options={doctorsOptions}
                >
                    <Component data={data} />
                </FilterLayout>
            </PageCard>
        </ListLayout>
    )
}

export default Doctors