import { useEffect, useMemo } from "react"
import { useState } from "react"
import { statusColors } from "../../../core/utils/theme"
import Text from "../../../core/utils/Text"
import { FaEye } from "react-icons/fa6";
import { IconWrapper } from "../../../styles/CommonStyles"
import ActionButton from "../../../components/common/buttons/ActionButton"
import { useQueryContext } from "../../../core/contexts/query"
import { useUpdateUserKyc } from "../../../core/services/react-query/profile"
import useGetProfileOrUser from "../../../core/hooks/main/users/useGetProfileOrUser"
import { AcceptRejectBtns, ProcessInput, ProcessInputWrapper, ShowImageFullScreen } from "./KycHelpers";


const ProfileInfo = ({
    detail = false,
    user: userDetail,
    refetch
}) => {

    const [kycStatus, setKycStatus] = useState('created')
    const [processedData, setProcessedData] = useState({})

    const { user } = useGetProfileOrUser(detail, userDetail)

    useEffect(() => {
        if (!!user?.id) {
            setKycStatus(user.is_kyc_passed)
            setProcessedData(user.kyc)
        }
    }, [user])

    const [processInputKeys] = useState([
        'first_name', 'last_name',
        'father_name', 'gender',
        'birthday', 'nationality',
        'address', 'passport_number'
    ])

    const changeStatus = (id, value) => {
        setProcessedData(state => ({ ...state, [id]: value }))
    }

    const [showImage, setShowImage] = useState('')

    const validAction = useMemo(() => {
        const list = Object.keys(processedData)
        for (let i = 0; i < list.length; i++) {
            if (processedData[list[i]] === 'not processed' || !processedData[list[i]]) {
                return false
            }
        }
        return true
    }, [processedData])


    const { mutate: updateKyc, isLoading } = useUpdateUserKyc(refetch)

    const { setToast } = useQueryContext()
    const onSubmitKyc = () => {
        if (!validAction) {
            setToast({ message: 'accept-reject-all-items', isError: true, show: true })
            return
        }
        if (isLoading) return
        updateKyc({
            ...processedData,
            user_id: user.id
        })
    }

    const onAcceptAll = () => {
        let temp = {}
        Object.keys(processedData).forEach(k => {
            temp[k] = 'OK'
        })
        setProcessedData(temp)
    }

    const onRejectAll = () => {
        let temp = {}
        Object.keys(processedData).forEach(k => {
            temp[k] = 'NOK'
        })
        setProcessedData(temp)
    }


    return (
        <div className={'flex flex-col w-full gap-12 p-12'}>
            <div className={'flex items-center gap-4 dark:text-gray-200'}>
                <span>
                    <Text tid={'kyc-status'} />
                    <span>:</span>
                </span>
                <div
                    style={{ border: `1px solid ${statusColors[kycStatus]}`, color: statusColors[kycStatus] }}
                    className={`rounded-md px-10 py-2`}
                >
                    <Text tid={kycStatus} />
                </div>
            </div>

            {user?.is_kyc_passed === 'created' ?
                <div className={'flex flex-col w-full gap-12 p-12 flex items-center justify-center dark:text-gray-100'}>
                    <Text tid={'user-not-ready-for-kyc'} />
                </div>
                :
                <>

                    <div className={'grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8 w-full'}>
                        {processInputKeys?.map(k => {

                            return (
                                <ProcessInput
                                    key={k}
                                    id={k}
                                    value={user?.[k] || ''}
                                    status={processedData[k] || 'not processed'}
                                    changeStatus={changeStatus}
                                    detail={detail}
                                />
                            )
                        })}
                    </div>

                    <div className={'grid md:grid-cols-2 w-full'}>

                        {['original_passport', 'personal_photo'].map(k => {

                            return (
                                <div className={'flex items-center justify-center'}>
                                    <ProcessInputWrapper className={`
                        w-[240px] h-[300px] rounded-md dark:text-gray-300
                    `}
                                        statusColor={statusColors[processedData[k] || 'not processed']}
                                    >
                                        {!!user?.[k] ?
                                            <img
                                                src={user?.[k]?.url}
                                                className={'w-[240px] h-[240px] rounded-md'}
                                                alt={'personal-ph'}
                                            />
                                            :
                                            <div className={'w-[240px] h-[240px] flex items-center justify-center'}>
                                                <Text tid={'no-image-uploaded'} />
                                            </div>
                                        }
                                        <div className={'flex items-center justify-between px-5 py-2'}>
                                            <IconWrapper onClick={() => setShowImage(k)}>
                                                <FaEye size={24} />
                                            </IconWrapper>
                                            {detail ?
                                                <AcceptRejectBtns
                                                    status={processedData[k] || 'not processed'}
                                                    onAcceptClick={() => changeStatus(k, 'OK')}
                                                    onRejectClick={() => changeStatus(k, 'NOK')}
                                                />
                                                :
                                                processedData['personal_photo'] || 'not processed'
                                            }
                                        </div>
                                    </ProcessInputWrapper>
                                </div>
                            )
                        })}
                    </div>

                    <div className={'flex items-center justify-center gap-8'}>
                        <div
                            onClick={onAcceptAll}
                            className={`
                            w-[144px] rounded-md flex items-center justify-center 
                            h-[42px] border-[1px] border-slate-500 border-opacity-50
                            cursor-pointer bg-green-500 bg-opacity-50 text-gray-200
                        `}>
                            <Text tid={'accept-all'} className={'text-sm'} />
                        </div>
                        <div
                            onClick={onRejectAll}
                            className={`
                            w-[144px] rounded-md flex items-center justify-center 
                            h-[42px] border-[1px] border-slate-500 border-opacity-50
                            cursor-pointer bg-red-500 bg-opacity-50 text-gray-200
                        `}>
                            <Text tid={'reject-all'} className={'text-sm'} />
                        </div>
                        <ActionButton
                            active={validAction}
                            width={'300px'}
                            height={'42px'}
                            onClick={onSubmitKyc}
                            loading={isLoading}
                        >
                            <Text tid={'submit-kyc'} />
                        </ActionButton>
                    </div>
                </>
            }


            {showImage ?
                <ShowImageFullScreen
                    image={user?.[showImage]}
                    showImage={showImage}
                    onClose={() => setShowImage('')}
                />
                : null}
        </div>
    )
}

export default ProfileInfo
