
export const CacheKeys = {
    users: 'users',
    transactions: 'transactions',
    doctors: 'doctors',
    time_slots: 'time_slots',
    stakings: 'stakings'
}

export const filtersIntitialState = () => {
    const keys = Object.keys(CacheKeys)
    const initial = {}
    keys.forEach(key => {
        const value = CacheKeys[key]
        initial[value] = {}
    })
    return initial
}


export const paginationInitialState = () => {
    const keys = Object.keys(CacheKeys)
    const initial = {}
    keys.forEach(key => {
        const value = CacheKeys[key]
        initial[value] = { page: 1, per_page: 10 }
    })
    return initial
}

const verifyOptions = ['created', 'pending', 'accepted', 'rejected']
const contactTypeOptions = ['online-video-call', 'phone', 'visit', 'chat']


export const userFilterOptions = [
    { name: '_id', search: 'id' },
    { name: 'fullname', search: 'fullname' },
    { name: 'mobile', search: 'mobile' },
    { name: 'email', search: 'email' },
    { name: 'userStatus', search: 'user_status', options: verifyOptions },
    { name: 'agentStatus', search: 'agent_status', options: verifyOptions },
    { name: 'date', search: 'date' },
]

export const transactionOptions = [
    { name: '_id', search: 'id' },
    { name: 'username', search: 'fullname' },
    // { name: 'contactType', search: 'contact_type', options: contactTypeOptions },
    { name: 'amountFrom', search: 'amount_from' },
    { name: 'amountTo', search: 'amount_to' },
    { name: 'date', search: 'date' }
]

export const doctorsOptions = [
    { name: '_id', search: 'id' },
    { name: 'fullname', search: 'fullname' },
    { name: 'email', search: 'email' },
    { name: 'specialization', search: 'specialization' },
    { name: 'mobile', search: 'mobile' }
]

export const timeSlotOptions = [
    { name: '_id', search: 'id' },
    { name: 'doctorName', search: 'doctor_name' },
    { name: 'specialization', search: 'specialization' },
    { name: 'contactType', search: 'contact_type', options: contactTypeOptions},
    { name: 'time_slot_date', search: 'time_slot_date' }
]

export const stakeOptions = [
    { name: '_id', search: 'id' },
]

