import { useEffect, useState } from 'react'
import Input from '../../../components/common/input/Input'
import {
	useGetContactTypes,
	useGetDoctorFees,
	useUpdateDoctorFees,
} from '../../../core/services/react-query/medical'
import Text from '../../../core/utils/Text'
import { ClipLoader } from 'react-spinners'

const DoctorFees = () => {
	const { data: allContacts } = useGetContactTypes()
	const { data: allFees } = useGetDoctorFees()
	const { mutate: changeFees, isLoading: updateFeeLoading } = useUpdateDoctorFees()

	const [input, setInput] = useState({})
	useEffect(() => {
		if (allFees && allContacts) {
			let temp = {}
			for (const contact of allContacts) {
				const fee = allFees?.find((x) => x.contact_type_id === contact.id)
				temp[contact.type] = { price: fee?.price || 0, id: contact.id }
			}
			setInput(temp)
		}
	}, [allFees, allContacts])

	const onChangeFees = () => {
		const keys = Object.keys(input)
		for (const k of keys) {
			const price = input[k].price
			const contact_type_id = input[k].id

			const fee = allFees?.find((x) => x.contact_type_id === contact_type_id)
			if (fee?.price === price) continue

			changeFees({ price, contact_type_id })
		}
	}

	return (
		<div
			className={`
                        rounded border-[1px] border-slate-200 border-opacity-20
                    p-5 dark:bg-bgDarkPrimary h-[320px] overflow-y-auto
                    text-sm dark:text-gray-100
                    `}
		>
			<div
				className={
					'flex items-center justify-center pb-2 border-b-[1px] border-slate-500 border-opacity-10'
				}
			>
				<Text tid={'set-doctor-fees'} />
			</div>
			<div className={'grid grid-cols-2 gap-3 w-full h-[80%] py-3'}>
				{allContacts?.map((contact) => {
					return (
						<div className={'flex flex-col'}>
							<Text tid={contact.type} />
							<Input
								value={input[contact.type]?.price || 0}
								onInputChange={(v) =>
									setInput((state) => ({
										...state,
										[contact.type]: { price: v, id: state[contact.type].id },
									}))
								}
								className={'h-[42px] w-full'}
								size={'small'}
							/>
						</div>
					)
				})}

				<div
					className={
						'col-span-2 bg-blue-500 flex items-center justify-center rounded h-[44px] w-full'
					}
					onClick={onChangeFees}
				>
					{updateFeeLoading ? <ClipLoader color={'#fff'} size={18} /> : <Text tid={'submit'} />}
				</div>
			</div>
		</div>
	)
}

export default DoctorFees
