import { useMutation, useQuery } from "react-query"
import { normalFetch } from "../../../fetch-api/get"
import { postApi } from "../../../fetch-api/post"
import { useQueryContext } from "../../../../contexts/query"


export const stakingSettingKeys = {
    getCurrencyStakingSetting: 'getCurrencyStakingSetting',
    updateCurrencyStakingSetting: 'updateCurrencyStakingSetting'
}


export const useGetCurrencyStakingSetting = () => {

    return useQuery({
        queryFn: () => normalFetch('wallets/admin/settings/indexStakings'),
        queryKey: [stakingSettingKeys.getCurrencyStakingSetting],
        select: res => res?.data?.data
    })
}

export const useUpdateCurrencyStakingSetting = (onSuccess) => {

    const { queryClient, setToast } = useQueryContext()

    return useMutation({
        mutationFn: (payload) => postApi(payload, 'wallets/admin/settings/updateStakings'),
        mutationKey: [stakingSettingKeys.updateCurrencyStakingSetting],
        onSuccess: () => {
            onSuccess()
            queryClient.invalidateQueries([stakingSettingKeys.getCurrencyStakingSetting])
            setToast({ message: 'operation-success', show: true })
        }
    })
}
