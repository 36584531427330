import { Background, CFlex, CMargin, DText } from '../../../../styles/CommonStyles'
import Text from '../../../../core/utils/Text'
import { forwardRef, useState, useImperativeHandle, useEffect } from 'react'
import { useFilterContext } from '../../../../core/contexts/filter'
import { useMainContext } from '../../../../core/contexts/main'
import DatePicker from 'react-multi-date-picker'
import persian from 'react-date-object/calendars/persian'
import persian_fa from 'react-date-object/locales/persian_fa'
import transition from 'react-element-popper/animations/transition'
import 'react-multi-date-picker/styles/colors/teal.css'
import 'react-multi-date-picker/styles/backgrounds/bg-dark.css'
import DateInput from '../../../common/input/DateInput'
import { useWindowSize } from '../../../../core/hooks/common/useWindowSize'
import { MOBILE_SIZE } from '../../../../core/constants/common'
import 'react-multi-date-picker/styles/layouts/mobile.css'

const DateFilter = forwardRef((props, ref) => {
	const { filter, onClose, single = false } = props
	const { name } = filter
	const { width } = useWindowSize()
	const { lang, theme } = useMainContext()
	const { filters: _filters, setFilters, cache } = useFilterContext()
	const filters = _filters[cache]

	const [date, setDate] = useState(filters.date ? filters.date : null)
	const [dateFrom, setDateFrom] = useState(filters.dateFrom ? filters.dateFrom : null)
	const [dateTo, setDateTo] = useState(filters.dateTo ? filters.dateTo : null)

	useImperativeHandle(ref, () => ({
		submitFilters: () => {
			const newState = { ...filters }
			if (dateFrom) newState.dateFrom = dateFrom
			if (dateTo) newState.dateTo = dateTo
			if (date) newState[filter.name] = date
			setFilters(newState)
			onClose()
		},
	}))

	return (
		<CFlex style={{ padding: '8px 0' }}>
			<Background bg={'primaryBg'}>
				<DText main>
					<Text tid={`filter-${name}`} />
				</DText>
				<CMargin margin={'5px'} />
				{single ? (
					<DatePicker
						calendar={lang === 'fa' && persian}
						locale={lang === 'fa' && persian_fa}
						value={date}
						onChange={setDate}
						animations={[transition()]}
						render={<DateInput label={filter.search} />}
						className={`${theme === 'dark' && 'bg-dark custom-calendar'} teal ${
							width < MOBILE_SIZE && 'rmdp-mobile'
						}`}
						calendarPosition={lang === 'fa' ? 'left-top' : 'right-top'}
					/>
				) : (
					<>
						<DatePicker
							calendar={lang === 'fa' && persian}
							locale={lang === 'fa' && persian_fa}
							value={dateFrom}
							onChange={setDateFrom}
							animations={[transition()]}
							render={<DateInput label={'dateFrom'} />}
							className={`${theme === 'dark' && 'bg-dark custom-calendar'} teal ${
								width < MOBILE_SIZE && 'rmdp-mobile'
							}`}
							calendarPosition={lang === 'fa' ? 'left-top' : 'right-top'}
						/>
						<DatePicker
							calendar={lang === 'fa' && persian}
							locale={lang === 'fa' && persian_fa}
							value={dateTo}
							onChange={setDateTo}
							animations={[transition()]}
							render={<DateInput label={'dateTo'} />}
							className={`${theme === 'dark' && 'bg-dark custom-calendar'} teal ${
								width < MOBILE_SIZE && 'rmdp-mobile'
							}`}
							calendarPosition={lang === 'fa' ? 'left-top' : 'right-top'}
						/>
					</>
				)}
			</Background>
		</CFlex>
	)
})

export default DateFilter
