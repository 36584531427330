import { useFilterContext } from "../../contexts/filter";

const useGetTableIndex = () => {


    const { pagination: _pagination, cache } = useFilterContext()
    const pagination = _pagination[cache]

    const getTableIndex = (idx) => {
        const { page, per_page } = pagination
        return (idx + 1) + (page - 1) * per_page
    }

    return { getTableIndex }
}


export default useGetTableIndex
