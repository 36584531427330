import { DText, Flex } from '../../../styles/CommonStyles'

const CoinRow = ({ coin }) => {
	let imgSrc
	try {
		imgSrc = coin.images[0].url
	} catch (err) {}

	return (
		<Flex justify={'flex-start'}>
			{!!imgSrc ? <img src={imgSrc} width='28px' alt={' '} style={{ margin: '0 5px' }} /> : null}
			<DText main fontSize={'m'}>
				{coin?.abbr}
			</DText>
		</Flex>
	)
}

export default CoinRow
