import { useState } from "react"
import { CacheKeys, stakeOptions } from "../../../../core/constants/filter"
import { useGetAllStakings } from "../../../../core/services/react-query/report/staking"
import { UserDetailListWrapper } from "../../../../styles/main/user"
import FilterLayout from "../../../layout/filter/FilterLayout"
import { useWindowSize } from "../../../../core/hooks/common/useWindowSize"
import { TABLET_SIZE } from "../../../../core/constants/common"
import StakingList from "../../reports/stakings/StakingList"
import RStakingList from "../../reports/stakings/RStakingList"



const UserStaking = ({ user }) => {


    const { width } = useWindowSize()
    const [stakes, setStakes] = useState({ loading: true, data: null })
    const Component = width > TABLET_SIZE ? StakingList : RStakingList

    return (
        <UserDetailListWrapper>
            <FilterLayout
                query={useGetAllStakings}
                options={stakeOptions}
                onQuerySuccess={setStakes}
                cache={CacheKeys.stakings}
                extra={{ user_id: user?.id }}
            >
                <Component
                    data={stakes}
                />
            </FilterLayout>
        </UserDetailListWrapper>
    )
}

export default UserStaking