import axios from "axios"
import { BASE_URL, SOCKET_URL } from "../../constants/urls";


/**
 * Get api with filter queries
 * @param {Object} params
 * @param {string} path
 * @returns {Promise}
 */
const filterFetch = (params, path) => {
    return axios.get(
        BASE_URL + path, {
        params
    }
    )
}

/**
 * Get api
 * @param {string} path
 * @returns {Promise}
 */
const normalFetch = (path) => {

    return axios.get(BASE_URL + path)
}


const getPing = () => {
    return axios.get(SOCKET_URL + 'ping')
}

const dataFetch = (data, path) => {
    return axios.get(BASE_URL + path, {
        params: data
    })
}


export {
    filterFetch,
    normalFetch,
    getPing,
    dataFetch
}
