import {useQuery} from "react-query";
import {filterFetch, normalFetch} from "../../fetch-api/get";

export const panelKeys = {
    inAndOutExchange: 'inAndOutExchange',
    allFeeAmount: 'allFeeAmount',
    allStakingAmount: 'allStakingAmount'
}


export const useGetInAndOutExchange = () => {

    return useQuery({
        queryFn: () => normalFetch('wallets/admin/all-wallets/statistics/all_user_staking_amount'),
        queryKey: panelKeys.inAndOutExchange,
        select: res => res?.data?.data
    })
}

export const useGetAllFeeAmount = () => {

    return useQuery({
        queryFn: () => normalFetch('wallets/admin/all-wallets/statistics/all_fee_amount'),
        queryKey: panelKeys.allFeeAmount,
        select: res => res?.data?.data
    })
}

export const useGetAllStakingAmount = () => {

    return useQuery({
        queryFn: () => normalFetch('wallets/admin/all-wallets/statistics/all_user_staking_amount'),
        queryKey: panelKeys.allStakingAmount,
        select: res => res?.data?.data
    })
}