import { useEffect, useMemo, useState } from 'react'
import useGetProfileOrUser from '../../../core/hooks/main/users/useGetProfileOrUser'
import { useUpdateUserKyc } from '../../../core/services/react-query/profile'
import { useQueryContext } from '../../../core/contexts/query'
import Text from '../../../core/utils/Text'
import { statusColors } from '../../../core/utils/theme'
import { AcceptRejectBtns, ProcessInputWrapper, ShowImageFullScreen } from './KycHelpers'
import { IconWrapper } from '../../../styles/CommonStyles'
import { FaEye } from 'react-icons/fa6'
import ActionButton from '../../common/buttons/ActionButton'

const AgentInfo = ({ detail = false, user: userDetail, refetch }) => {
	const [kycStatus, setKycStatus] = useState('created')
	const [processedData, setProcessedData] = useState({})

	const changeStatus = (id, value) => {
		setProcessedData((state) => ({ ...state, [id]: value }))
	}

	const { user } = useGetProfileOrUser(detail, userDetail)

	useEffect(() => {
		if (!!user?.id) {
			setKycStatus(user.is_agent_status)
			setProcessedData(user['agent-kyc'])
		}
	}, [user])

	const [showImage, setShowImage] = useState('')

	const validAction = useMemo(() => {
		const list = Object.keys(processedData)
		for (let i = 0; i < list.length; i++) {
			if (processedData[list[i]] === 'not processed' || !processedData[list[i]]) {
				return false
			}
		}
		return true
	}, [processedData])

	const { mutate: updateKyc, isLoading } = useUpdateUserKyc(refetch)

	const { setToast } = useQueryContext()
	const onSubmitKyc = () => {
		if (!validAction) {
			setToast({ message: 'accept-reject-all-items', isError: true, show: true })
			return
		}
		if (isLoading) return
		updateKyc({
			...processedData,
			user_id: user.id,
		})
	}

	return (
		<div className={'flex flex-col w-full gap-12  p-12'}>
			<div className={'flex items-center gap-4 dark:text-gray-200'}>
				<span>
					<Text tid={'kyc-status'} />
					<span>:</span>
				</span>
				<div
					style={{ border: `1px solid ${statusColors[kycStatus]}`, color: statusColors[kycStatus] }}
					className={`rounded-md px-10 py-2`}
				>
					<Text tid={kycStatus} />
				</div>
			</div>

			{user?.is_kyc_passed !== 'accepted' ? (
				<div
					className={
						'flex flex-col w-full gap-12 p-12 flex items-center justify-center dark:text-gray-100'
					}
				>
					<Text tid={'user-kyc-not-accepted'} />
				</div>
			) : user?.is_agent_status === 'created' ? (
				<div
					className={
						'flex flex-col w-full gap-12 p-12 flex items-center justify-center dark:text-gray-100'
					}
				>
					<Text tid={'user-not-ready-for-kyc'} />
				</div>
			) : (
				<div className={'grid md:grid-cols-2 w-full'}>
					{['birth_certificate', 'national_card'].map((k) => {
						return (
							<div className={'flex items-center justify-center'}>
								<ProcessInputWrapper
									className={`
                            w-[240px] h-[300px] rounded-md dark:text-gray-300
                        `}
									statusColor={statusColors[processedData[k] || 'not processed']}
								>
									{!!user?.[k] ? (
										<img
											src={user?.[k]?.url}
											className={'w-[240px] h-[240px] rounded-md'}
											alt={'personal-ph'}
										/>
									) : (
										<div className={'w-[240px] h-[240px] flex items-center justify-center'}>
											<Text tid={'no-image-uploaded'} />
										</div>
									)}
									<div className={'flex items-center justify-between px-5 py-2'}>
										<IconWrapper onClick={() => setShowImage(k)}>
											<FaEye size={24} />
										</IconWrapper>
										{detail ? (
											<AcceptRejectBtns
												status={processedData[k] || 'not processed'}
												onAcceptClick={() => changeStatus(k, 'OK')}
												onRejectClick={() => changeStatus(k, 'NOK')}
											/>
										) : (
											processedData['personal_photo'] || 'not processed'
										)}
									</div>
								</ProcessInputWrapper>
							</div>
						)
					})}
				</div>
			)}

			<ActionButton
				active={validAction}
				width={'300px'}
				height={'42px'}
				onClick={onSubmitKyc}
				loading={isLoading}
			>
				<Text tid={'submit-agent'} />
			</ActionButton>

			{showImage ? (
				<ShowImageFullScreen
					image={user?.[showImage]}
					showImage={showImage}
					onClose={() => setShowImage('')}
				/>
			) : null}
		</div>
	)
}

export default AgentInfo
