
import { useQuery } from "react-query"
import { normalFetch } from "../../../fetch-api/get"

export const currencyKeys = {
    getCurrencies: 'getCurrencies'
}


export const useGetCurrencies = () => {

    return useQuery({
        queryFn: () => normalFetch('wallets/admin/currency'),
        queryKey: [currencyKeys.getCurrencies],
        select: res => res?.data?.data
    })
}