import { useMutation } from "react-query"
import { postApi } from "../../fetch-api/post"


export const settingKeys = {
    uploadImage: 'uploadImage'
}


export const useUploadImage = (onSuccess) => {

    return useMutation({
        mutationFn: (body) => postApi(body, 'packages/admin/images/upload'),
        mutationKey: [settingKeys.uploadImage],
        onSuccess
    })
}