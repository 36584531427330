import { Link } from "react-router-dom";
import Text from "../../../../core/utils/Text";
import { Row } from "../../../../styles/CommonStyles";
import RTableLayout from "../../../responsive/layout/RTableLayout";
import { formatDate, formatNumber } from "../../../../core/utils/common";
import { useMainContext } from "../../../../core/contexts/main";


const RTransactionsList = ({ data }) => {

    const { lang } = useMainContext()
    const typeBgs = {
        Deposit: 'bg-yellow-300',
        Visit: 'bg-blue-300'
    }


    return (
        <RTableLayout
            data={data}
        >
            {data?.data?.list?.map((item, idx) => {

                return (
                    <Row index={idx} cs={'100%'} key={item.id}>
                        <div className={'grid grid-cols-2 gap-3'}>
                            <Text tid={'username'} />
                            <Link to={`/users/${item.user.id}`}>
                                <span className={'underline'}>{
                                    `${item.user_full_name?.length > 2 ? item.user_full_name : '--'} 
                                    ${item.user.doctor_specialization?.name ?
                                        `(${item.user.doctor_specialization?.name})`
                                        : ''
                                    }`
                                }</span>
                            </Link>

                            <div className={'col-span-2 mx-auto w-full bg-opacity-20 h-[1px] bg-gray-500'} />
                            <Text tid={'wallet'} />
                            <span>{item.wallet}</span>

                            <div className={'col-span-2 mx-auto w-full bg-opacity-20 h-[1px] bg-gray-500'} />
                            <Text tid={'type'} />
                            <div className={`
                                rounded border-[1px] dark:border-gray-400 border-gray-600 border-opacity-50 px-10 py-2
                                ${typeBgs[item.type] || 'bg-green-500'} bg-opacity-20 w-min
                                `}>
                                <span>{item.type}</span>
                            </div>

                            <div className={'col-span-2 mx-auto w-full bg-opacity-20 h-[1px] bg-gray-500'} />
                            <Text tid={'amount'} />
                            <span>{formatNumber(item.amount, { type: 'usdt' })}</span>

                            <div className={'col-span-2 mx-auto w-full bg-opacity-20 h-[1px] bg-gray-500'} />
                            <Text tid={'before_balance'} />
                            <span>{formatNumber(item.before_balance, { type: 'usdt' })}</span>

                            <div className={'col-span-2 mx-auto w-full bg-opacity-20 h-[1px] bg-gray-500'} />
                            <Text tid={'after_balance'} />
                            <span>{formatNumber(item.after_balance, { type: 'usdt' })}</span>


                            <div className={'col-span-2 mx-auto w-full bg-opacity-20 h-[1px] bg-gray-500'} />
                            <Text tid={'date'} />
                            <span>
                                {`${formatDate(item.created_at, 'date', lang)} ${formatDate(item.created_at, 'time', lang)}`}
                            </span>

                        </div>
                    </Row>
                )
            })}
        </RTableLayout>
    )
}

export default RTransactionsList